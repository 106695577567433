<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'DoAndWin',
  beforeCreate() {
    this.$store.dispatch('app/setActiveNavItem', 'dowin');
  },
};
</script>
<style lang="scss" scoped></style>
